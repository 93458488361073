<template>
  <div class="expande-horizontal column pt-0">
    <v-flex xs12>
      <div class="windows-style expande-horizontal fonte column">
        <transition name="slide-fade">
          <v-list-item class="pa-0 ma-0">
            <v-avatar
              @click="backToList"
              style="cursor: pointer;"
              size="27"
              :color="$theme.primary"
              class="mr-2"
              icon
            >
              <v-icon color="#333">mdi-close</v-icon>
            </v-avatar>
            <v-list-item-content>
              <v-list-item-title class="font-weight-bold">
                {{ get_cliente.new ? "Novo cliente" : get_cliente.nome }}
              </v-list-item-title>
              <v-list-item-subtitle class="font-mini">
                Gerencie os detalhes abaixo
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action v-if="get_cliente._id">
              <v-btn
                color="red"
                @click="excluir_cliente_dialog(get_cliente)"
                outlined
                small
                >Excluir</v-btn
              >
            </v-list-item-action>
          </v-list-item>
        </transition>
      </div>
    </v-flex>
    <div class="windows-style-content expande-horizontal wrap">
      <v-flex xs12>
        <v-form ref="form">
          <v-flex class="px-7" xs12>
            <span class="py-3 fonte" :style="`color: ${$theme.primary}`">
              Nome
            </span>
            <v-text-field
              v-model="get_cliente.nome"
              dense
              solo
              flat
              outlined
              clearable
              :color="$theme.primary"
              :rules="[v => !!v || 'Preencha este campo']"
              label="ex: Roberto"
            ></v-text-field>
          </v-flex>

          <v-flex class="px-7" xs12>
            <span class="py-3 fonte" :style="`color: ${$theme.primary}`">
              Email
            </span>
            <v-text-field
              v-model="get_cliente.email"
              dense
              solo
              flat
              outlined
              clearable
              :color="$theme.primary"
              label="ex: meuemail@gmail.com"
            ></v-text-field>
          </v-flex>

          <div class="expande-horizontal">
            <v-flex class="px-7" xs6>
              <span class="py-3 fonte" :style="`color: ${$theme.primary}`">
                CPF
              </span>
              <v-text-field
                v-model="get_cliente.cpf"
                dense
                solo
                flat
                outlined
                v-mask="['###.###.###-##']"
                clearable
                :color="$theme.primary"
                label="ex: 000.000.000-00"
              ></v-text-field>
            </v-flex>
          </div>

          <div class="expande-horizontal">
            <v-flex class="px-7" xs6>
              <span class="py-3 fonte" :style="`color: ${$theme.primary}`">
                Telefone
              </span>
              <v-text-field
                v-model="get_cliente.telefone"
                dense
                solo
                flat
                outlined
                v-mask="['(##) # #### ####']"
                clearable
                :color="$theme.primary"
                label="ex: (96) 9 8419-6827"
              ></v-text-field>
            </v-flex>
          </div>
          <v-flex class="px-7" xs6>
            <span class="py-3 fonte" :style="`color: ${$theme.primary}`">
              Nascimento
            </span>
            <v-text-field
              v-model="get_cliente.data_nascimento"
              dense
              solo
              flat
              outlined
              v-mask="['##/##/####']"
              clearable
              :color="$theme.primary"
              label="ex: 12/12/1984"
            ></v-text-field>
          </v-flex>
        </v-form>
      </v-flex>
    </div>

    <div class="expande-horizontal">
      <v-btn large dark @click="valida_form" tile block color="green">
        <span class="fonte"> Salvar </span>
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["get_cliente", "getLoggedUser"])
  },
  methods: {
    ...mapActions([
      "criar_cliente",
      "atualizar_cliente",
      "fecha_modal_view_cliente"
    ]),
    valida_form() {
      if (this.$refs.form.validate()) {
        this.get_cliente._id ? this.atualizar_cliente() : this.criar_cliente();
      }
    },
    backToList() {
      this.$store.commit("set_cliente", {});
      this.$forceUpdate();
    }
  }
};
</script>
